<template>
	<div>
		<v-card
			outlined
			id="dropzone"
			ref="dzone"
			tabindex="0"
			title="Click to grap a file from your PC!"
			width="100%"
			:class="[
				'px-2 py-6 d-flex flex-column justify-center align-center',
				!dragover || 'elevation-6',
			]"
			@click.stop="$refs.upload.click()"
		>
			<input ref="upload" :multiple="multiple" id="fileUpload" type="file" class="d-none" />
			<span v-if="file == null || file.length == 0" class="d-flex flex-column">
				<v-icon class="mx-auto" x-large color="secondary">mdi-folder-open</v-icon>Haz click aquí o arrastra los archivos
			</span>
			<span v-else class="d-flex flex-wrap" style="gap: 10px">
				<v-chip v-for="f in file" label>{{ f.name }}</v-chip>
			</span>
		</v-card>
		<v-card-actions v-if="file && file.length > 0">
			<v-spacer />
			<v-btn text color="error" @click.stop="file = []">
				<v-icon left>mdi-close</v-icon>Quitar
			</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
export default {
	// internal properties
	// formUpload: boolean = false
	// dragover: boolean = false
	props: ['value', 'multiple'],
	computed: {
		file: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},

	data: () => ({
		dragover: false,
		formUpload: false,
	}),
	mounted() {
		// to register listeners, we have to know the
		// html elements
		const dropzone = this.$refs.dzone.$el;
		const fileupload = this.$refs.upload;
		// register listeners on your dropzone / v-sheet
		if (dropzone) {
			// register all drag & drop event listeners
			dropzone.addEventListener("dragenter", (e) => {
				e.preventDefault();
				this.dragover = true;
			});
			dropzone.addEventListener("dragleave", (e) => {
				e.preventDefault();
				this.dragover = false;
			});
			dropzone.addEventListener("dragover", (e) => {
				e.preventDefault();
				this.dragover = true;
			});
			dropzone.addEventListener("drop", (e) => {
				e.preventDefault();
				const dragevent = e;
				if (dragevent.dataTransfer) {
					this.filesSelected(dragevent.dataTransfer.files);
				}
			});
			// register event listener for keyboard usage
			// dropzone.addEventListener("click", (e) => {
			// 	e.preventDefault();
			// 	if (fileupload) {
			// 		fileupload.click();
			// 	}
			// });
			dropzone.addEventListener("keypress", (e) => {
				e.preventDefault();
				const keyEvent = e;
				if (keyEvent.key === "Enter") {
					if (fileupload) fileupload.click();
				}
			});
			// register listeners on the file input
			if (fileupload) {
				fileupload.addEventListener("change", (e) => {
					const target = e.target;
					if (target.files) {
						this.filesSelected(target.files);
					}
				});
			}
		}
	},
	methods: {
		filesSelected(fileList) {
			// this.$emit("filesAdded", fileList);
			this.dragover = false;
			// this.file = fileList
			this.file = fileList;
		},
	},
	/**
	 * upload event...
	 */
	// @Emit()
	// filesSelected(fileList: FileList) {
	//   this.dragover = false
	// }
};
</script>